/* You can add global styles to this file, and also import other style files */
/*import angular tree component*/

@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

/**/
.mat-dialog-container {
  overflow: visible !important;
}

/*แก้ไขstyle ในหน้า dictionary*/
.tree-node {

  font-size: 1.0rem;
  font-weight: 200;
  color: #3C4858;
  margin-left: 10px;
}

tree-node-expander {
  margin-left: 4px;
}

.toggle-children {
  background-image:
    url(https://www.flaticon.com/svg/static/icons/svg/120/120893.svg);
  width: 15px;
  height: 15px;
  transition: all 0.2s;
  margin-top: 7px;
}

.node-content-wrapper {
  margin-top: 3px;
}

input[type=checkbox],
input[type=radio] {
  width: 20px;
  box-sizing: border-box;
  padding: 1px;
  height: 20px;
  margin-top: 5px;
}



.node-content-wrapper-active,
.node-content-wrapper-focused {
  background-color: rgba(255, 255, 255, 0);
}

.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: none;
}

.node-content-wrapper-focused,
.node-content-wrapper:hover {
  box-shadow: none;
}

.node-content-wrapper-focused {
  background: none;
}

.node-content-wrapper:hover {
  background: none;
}


tree-viewport {
  height: auto;
}





.tree-children::before {
  content: "";
  position: absolute;
  border-left: 1.5px dotted #23527c;
  height: 100%;
  top: -18px;
  left: 12px
}

.tree-children {
  position: relative;
  padding-left: 20px;
  margin-left: 0px
}

div.tree-children tree-node>div::before {
  content: "";
  position: absolute;
  border-bottom: 1.5px dotted #23527c;
  width: 12px;
  margin-top: 15px;
  left: 15px
}



.ag-header-cell-label {
  font-size: 1.0625rem;
  font-weight: 300;
  color: #9c27b0 !important;
  /* font-family: var(--cui-body-font-family) !important; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.ag-header-cell-text {
  overflow: unset;
}

.ag-cell-value {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.ag-popup .ag-popup-child {
  max-height: initial;
}

.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#myGrid {
  flex: 1 1 0px;
  width: 100%;
}

.fa-hourglass-half {
  color: navy;
  font-size: 18px;
}

.fa-frown {
  color: navy;
  font-size: 18px;
}